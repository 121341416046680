.paragraph-element {
    
    color: #000000;
    &.-introduction {
        font-size: rem(26px);
        line-height: rem(38px);
        font-weight: 500;
    }
    
    &.-default {
        font-size: rem(22px);
        line-height: rem(35px);
        font-weight: 300;
    }

    strong, b {
        font-weight: bold;
    }

    ul, ol {
        margin-left: 60px;
        margin-bottom: 30px;
    }
    ul li {
        margin-bottom: 10px;
    }
    
    .abbreviation {
        position: relative;
        text-decoration: none;
        border-bottom: 3px dotted black;
        line-height: 20px;

        cursor:help;
        .tooltip {
            display: none;
        }

        &.-right {
            .tooltip {
                left: 0px;
            }
        }
        &.-left {
            .tooltip {
                right: 0px;
            }
        }
        &.-top {
            .tooltip {
                bottom: 30px;
            }
        }
        &.-bottom {
            .tooltip {
                top: 30px;
            }
        }
        &.-big {
            &.-open, &:hover { 
                .tooltip {
                    width: auto;
                    width: 50vw;
                    white-space: normal;
                }
            }
        }

        &.-sidebar {
            &.-open, &:hover { 
                .tooltip {
                    width: auto;
                    width: 200px;
                    white-space: normal;
                    white-space: normal;
                }
            }
        }


        
        &.-open, &:hover {
            .tooltip {
                display: block;
                position: absolute;
                z-index:10;
                white-space: nowrap;
                background-color: #1e1e1e;
                color: #fff;
                border-radius: 3px;
                box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.4);
                font-size: 14px;
                padding: 3px 5px;
            }
        }
    }
    
}

@media only screen and (max-width: @small-break-point) { 
    .paragraph-element {
        &.-introduction {
            font-size: rem(22px);
            line-height: rem(32px);
        }

        &.-default {
            font-size: rem(18px);
            line-height: rem(28px);
        }

        ul, ol {
            margin-bottom: 15px;
        }

    }
}
