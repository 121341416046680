/*
---
name: .stretch
category: Shared/Helpers
tag:
 - helpers
---

`.stretch` Define an element which stretches to the size of its parent

```html
<div class="rel" style="height: 55px;">
   <div class="stretch styleguide-grid-unit"> I'm stretched to the size of my parent</div>
</div>
```
*/
.stretch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*
---
name: .abs
category: Shared/Helpers
tag:
 - helpers
---

`.abs` Set the element position to absolute
*/
.abs {
  position: absolute;
}
/*
---
name: .rel
category: Shared/Helpers
tag:
 - helpers
---

`.rel` Set the element position to relative
*/
.rel {
  position: relative;
}
/*
---
name: .vcenter
category: Shared/Helpers
tag:
 - helpers
---

`.vcenter` Sets vertical-align to middle
*/
.vcenter {
  vertical-align: middle;
}
/*
---
name: .hcenter
category: Shared/Helpers
tag:
 - helpers
---

`.hcenter` Sets text-align to center
*/
.hcenter {
  text-align: center;
}
/*
---
name: .floatleft
category: Shared/Helpers
tag:
 - helpers
---

`.floatleft` Sets float to left
*/
.floatleft {
  float: left;
}
/*
---
name: .floatright
category: Shared/Helpers
tag:
 - helpers
---

`.floatright` Sets float to right
*/
.floatright {
  float: right;
}
/*
---
name: .hidden
category: Shared/Helpers
tag:
 - helpers
---

`.hidden` Sets display to none.
*/
.hidden {
  display: none;
}
/*
---
name: rem( pixels )
category: Shared/Mixins
template: mixin
tag:
 - mixin
parameters:
 - name: pixels
   description: The pixel value to convert into rem based on the 16px root font-size
   optional: false
---

This less function turns a pixel value into a rem value based on the root font-size set to 16px.

An example usage looks like this:

```less
.example {
	padding: rem( 15px ) rem( 12px )
}
```
*/
/*
---
name: .for( @array, @code )
category: Shared/Mixins
template: mixin
tag:
 - mixin
parameters:
 - name: array
   description: The array of values to loop over
   optional: false
 - name: code
   description: The code that is executed on every value. You can access the index with `@i` and the value with `@value`
   optional: false
---

This mixin allows to execute code over an array

An example usage looks like this:
```less
@colors: #1abc9c, #2ecc71, #3498db, #9b59b6;

.for(@colors, {
    .color-@{i} {
        color: @value;
    }
});
```

This generates:
```css
.color-1 {
  color: #1abc9c;
}
.color-2 {
  color: #2ecc71;
}
.color-3 {
  color: #3498db;
}
.color-4 {
  color: #9b59b6;
}
```
*/
/*
---
name: Global Reset
category: Shared
---

The lernetz css library has a set of reset style to have the same behaviour on every browser.
Furthermore this reset sets some global variables like:
 * body font-families
 * body line-height
 * body text-rendering
 * :root font-size for proper rem conversion
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: inherit;
  vertical-align: baseline;
}
:root {
  font-size: 16px;
}
html {
  font-size: 100%;
}
body {
  line-height: 1.5rem;
  color: #000000;
  font-family: 'Helvetica', sans-serif;
  text-rendering: optimizeLegibility;
  background-color: #FFF;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
select {
  vertical-align: middle;
}
textarea {
  width: 100%;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/*
 * Normalize the behaviour of all browsers
 *
 * Instead of setting box-sizing fix on all elements we only set it on the root element
 * and tell all elements to inherit it's property from the parent
 * That way it's possible to overwrite the box-sizing anywhere while keeping proper inheritance
 */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: #F57C63;
}
a > img {
  display: block;
}
/*
---
name: Colors
category: Shared/Variables
template: colors
tag:
 - variables
 - colors
colors:
 - variable: "@text-color"
   description: "The default text color on the body"
   value: "#3F3F40"
 - variable: "@text-inverted-color"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "@action-color"
   description: "The main action color used for links and buttons"
   value: "#EC6449"
 - variable: "@body-color"
   description: "The main background color"
   value: "#FFF"
---

The color variables that are used throughout the library. 
*/
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "@text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "@font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "@text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "@transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
---

These variables setups some global text formatting and styling.
*/
/*
---
name: Size settings
category: Shared/Variables
template: variables
tag:
 - variables
 - grid
variables:
 - name: "@grid-gutter"
   description: "The space between the grid children"
   default: "rem( 30px )"
 - name: "@grid-break-points"
   description: "The screensize when the grid should break."
   default: "rem(600px), rem(1024px)"
 - name: "@grid-break-labels"
   description: "The breakpoint label corresponding to the entie in the `@grid-break-points` list"
   default: "medium, large"
 - name: "@page-padding"
   description: "The padding for the .page-fluid component"
   default: "rem( 60px )"
---

These variables setups some global sizes mainly for the grid.
*/
/*
---
name: .button-default
category: Buttons/Default
tag:
 - component
 - button
---

The default button sets some basic properties so it is working as a `button` or `a` tag.

An example for a button:
```jade
button.button-default My Button
```

An example for a link:
```jade
a.button-default My Link
```
*/
.button-default {
  appearance: none;
  background: transparent;
  border: none;
  display: inline-block;
  cursor: pointer;
  outline: none;
}
/*
---
name: .grid
category: Layout/Grid
tag:
 - component
 - responsive
compile: true
---

A simple grid system that wraps children nodes based on the viewport width.

## Simple side by side rendering

The easiest use case is to use simple `.col-1`, `.col-1-2`, `.col-1-3`, `.col-1-4` for a side-by-side rendering.
The default value for all child elements of a `.grid` is equivalent to `.col-1`, so for only one child it will span the full width.

```jade
div.grid
	div.col-1-2 
		div.aigis-debug col-1-2
	div.col-1-2
		div.aigis-debug col-1-2
```
```jade
div.grid
	div.col-1-4 
		div.aigis-debug col-1-4
	div.col-3-4
		div.aigis-debug col-3-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the `@grid-break-points`, `@grid-break-labels` variables.
The default values define two breakpoints with labels: `.medium-X-X` and `.large-X-X`.
So an example grid with two breakpoints looks like this:

```jade
div.grid
	div.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1 medium-1-2 large-1-4
	div.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1 medium-1-2 large-1-4
	div.col-1-2.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1-2 medium-1-2 large-1-4
	div.col-1-2.medium-1-2.large-1-4.xlarge-1-2
		div.aigis-debug col-1-2 medium-1-2 large-1-4
```

**Note:** The `col-1` classes are not present on the first two nodes, since this is the default behaviour.


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid.-reverse
	div.col-1-4
		div.aigis-debug First column in DOM
	div.col-1-4
		div.aigis-debug Second column in DOM
	div.col-1-4
		div.aigis-debug Third column in DOM
	div.col-1-4
		div.aigis-debug Fourth column in DOM
```
## No gutter

If you dont need a spacing/gutter between the grid columns you can use the modifier `.-nogutter`.

```jade
div.grid.-nogutter
	div.col-1-4
		div.aigis-debug First column in DOM
	div.col-1-4
		div.aigis-debug Second column in DOM
	div.col-1-4
		div.aigis-debug Third column in DOM
	div.col-1-4
		div.aigis-debug Fourth column in DOM
```
*/
.grid {
  margin: 0 -0.9375rem;
  /**
	 * Generate all media breakpoints based on the @grid-break-points, @grid-break-labels variables.
	 */
}
.grid:after {
  content: "";
  display: table;
  clear: both;
}
.grid > * {
  float: left;
  position: relative;
  width: 100%;
  padding: 0 0.9375rem;
}
.grid.-reverse > * {
  float: right;
}
.grid.-nogutter {
  margin: 0;
}
.grid.-nogutter > * {
  padding: 0;
}
.grid .col-1 {
  width: 100%;
}
.grid .col-1-2 {
  width: 50%;
}
.grid .col-1-3 {
  width: 33.33333333%;
}
.grid .col-2-3 {
  width: 66.66666667%;
}
.grid .col-1-4 {
  width: 25%;
}
.grid .col-3-4 {
  width: 75%;
}
@media all and (min-width: 37.5rem) {
  .grid > .medium-1 {
    width: 100%;
  }
  .grid > .medium-1-2 {
    width: 50%;
  }
  .grid > .medium-1-3 {
    width: 33.33333333%;
  }
  .grid > .medium-2-3 {
    width: 66.66666667%;
  }
  .grid > .medium-1-4 {
    width: 25%;
  }
  .grid > .medium-3-4 {
    width: 75%;
  }
}
@media all and (min-width: 64rem) {
  .grid > .large-1 {
    width: 100%;
  }
  .grid > .large-1-2 {
    width: 50%;
  }
  .grid > .large-1-3 {
    width: 33.33333333%;
  }
  .grid > .large-2-3 {
    width: 66.66666667%;
  }
  .grid > .large-1-4 {
    width: 25%;
  }
  .grid > .large-3-4 {
    width: 75%;
  }
}
/*
---
name: img.responsive
category: Layout/Image
tag:
 - responsive
compile: true
---

This class makes the image scaling to its max width.

```jade
img.responsive(src='http://www.byui.edu/images/agriculture-life-sciences/flower.jpg')
```
*/
img.responsive {
  max-width: 100%;
  height: auto;
}
/*
---
name: .media-responsive
category: Layout/Media
tag:
 - responsive
compile: true
---

`.media-responsive` - This makes a the `.media-responsive` div a container for any media element (iframe, video, ... ) that responsive scales with a fix ratio.
The media element ( child ) needs the class `.stretch` to fully fill out the parents size.

The `.media-responsive` component has to predefined modifiers: `.-media-4-3`, `.-media-16-9` that define a fix ratio.

```jade
div.media-responsive.-media-16-9
	iframe.stretch(src="https://www.youtube.com/watch?v=TCd6PfxOy0Y")
```
*/
.media-responsive {
  width: 100%;
  position: relative;
  padding-bottom: 50%;
}
.media-responsive.-media-4-3 {
  padding-bottom: 75%;
}
.media-responsive.-media-16-9 {
  padding-bottom: 56.26%;
}
/*
---
name: .page-fluid
category: Page/Fluid
tag:
 - responsive
compile: true
---

`.page-fluid` centers the page content and adds a padding so the page sticks not to the browser viewport.
Furthermore it has a max-width based on the last `@grid-break-points` value.

```jade
div.page-fluid
	div.aigis-debug Some page content
```

## Max-width page

To broaden the width of the page you can add the modifier `.-unlimited`. Thsi will span the page to the full width.

```jade
div.page-fluid.-unlimited
	div.aigis-debug Some page content
```
*/
.page-fluid {
  max-width: 71.5rem;
  margin: auto;
  padding: 0 3.75rem;
}
.page-fluid.-unlimited {
  max-width: none;
}
/*
---
name: .row
category: Layout/Row
tag:
 - component
compile: true
---

This styling allow to place containers in a row. They will **not** break as the `.grid` but resize them accordingly.
Some benefits of the `.row` approach is the easy vertical centering and the usage of the `.col-auto` class.

## Basic row with vertical centering

You can use the sizing classes `.col-X-X` to define the width of a column as the following example shows. 

```jade
div.row
	div.col-1-4.aigis-debug col-1-4 with a lot of text that spans the column.
	div.col-3-4.aigis-debug col-3-4 content.
```

## Fixed row

There is a `.-fixed` modifier where each column has the same width.

```jade
div.row.-fixed
	div.aigis-debug evenly spreadout column
	div.aigis-debug evenly spreadout column with more content
	div.aigis-debug evenly spreadout column
	div.aigis-debug evenly spreadout column with much more content than the other
	div.aigis-debug.vcenter evenly spreadout column with .vcenter
```


## Auto sized column width and vcenter

There is a special `.col-auto` class that resizes the column to the size of its content.
This is usefull for example if you have a fixed size logo and the rest of the header has to resize.

```jade
div.row.-center
	div.col-auto.aigis-debug
		img(src="http://placehold.it/100x100")
	div.col-1.aigis-debug The rest
	div.col-auto.aigis-debug
		img(src="http://placehold.it/200x100")
```
*/
.row {
  display: table;
  width: 100%;
}
.row.-fixed {
  table-layout: fixed;
}
.row.-center > * {
  vertical-align: middle;
}
.row.-bottom > * {
  vertical-align: bottom;
}
.row.-padded > * + * {
  padding-left: 1.875rem;
}
.row > * {
  display: table-cell;
}
.row > *.col-1 {
  width: 100%;
}
.row > *.col-1-2,
.row > *.col-2-4 {
  width: 50%;
}
.row > *.col-1-3 {
  width: 33.33333333%;
}
.row > *.col-2-3 {
  width: 66.66666667%;
}
.row > *.col-1-4 {
  width: 25%;
}
.row > *.col-3-4 {
  width: 75%;
}
.row > *.col-auto {
  width: auto;
  white-space: nowrap;
}
/*
---
name: Colors
category: Shared/Variables
template: colors
tag:
 - variables
 - colors
colors:
 - variable: "@text-color"
   description: "The default text color on the body"
   value: "#3F3F40"
 - variable: "@text-inverted-color"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "@action-color"
   description: "The main action color used for links and buttons"
   value: "#EC6449"
 - variable: "@body-color"
   description: "The main background color"
   value: "#FFF"
---

The color variables that are used throughout the library. 
*/
/*
---
name: Global settings
category: Shared/Variables
template: variables
tag:
 - variables
variables:
 - name: "@text-line-height"
   description: "The line height of the body"
   default: "1.5rem"
 - name: "@font-family"
   description: "The font-family of the body"
   default: "'Helvetica', sans-serif"
 - name: "@text-rendering"
   description: "The text-rendering of the body"
   default: "optimizeLegibility"
 - name: "@transition-duration"
   description: "The duration of the slide transitions"
   default: "0.4s"
---

These variables setups some global text formatting and styling.
*/
/*
---
name: Size settings
category: Shared/Variables
template: variables
tag:
 - variables
 - grid
variables:
 - name: "@grid-gutter"
   description: "The space between the grid children"
   default: "rem( 30px )"
 - name: "@grid-break-points"
   description: "The screensize when the grid should break."
   default: "rem(600px), rem(1024px)"
 - name: "@grid-break-labels"
   description: "The breakpoint label corresponding to the entie in the `@grid-break-points` list"
   default: "medium, large"
 - name: "@page-padding"
   description: "The padding for the .page-fluid component"
   default: "rem( 60px )"
---

These variables setups some global sizes mainly for the grid.
*/
.answer-view.-wrong {
  color: red;
}
.answer-view .label {
  margin-left: 0.9375rem;
  padding-left: 0.625rem;
  position: relative;
  display: block;
}
.answer-view .label > .input {
  top: 0.6875rem;
  position: absolute;
  left: -0.9375rem;
  margin: 0;
}
.audio-element > .audio {
  width: 100%;
}
.audio-element > .caption-element {
  text-align: center;
}
.box-chapter {
  margin-bottom: 90px;
}
.box-chapter .mandatory {
  min-height: 34px;
}
.box-chapter .image {
  padding-bottom: 13px;
  border-bottom: 3px solid #F57C63;
  max-height: 203px;
}
.box-chapter .title {
  margin: 14px 0;
  color: #000000;
  height: 80px;
  overflow: hidden;
}
.box-chapter .title:before {
  content: '\00ab';
}
.box-chapter .title:after {
  content: "\00bb";
}
.box-chapter .link-container:hover .image {
  opacity: 0.8;
}
.box-chapter .link-container:hover .title {
  color: #F57C63;
}
.box-chapter .class > svg,
.box-chapter .duration > svg,
.box-chapter .label > svg {
  margin-right: 9px;
  color: #F57C63;
}
.box-chapter .class,
.box-chapter .duration {
  display: inline-block;
  width: 50%;
}
.box-chapter .label {
  margin-top: 12px;
  margin-bottom: 32px;
}
.box-chapter .export > .button-export {
  margin-right: 10px;
}
.box-chapter .export > .button-export:last-child {
  margin-right: 0px;
}
@media only screen and (max-width: 1024px) {
  .box-chapter .image {
    max-height: 307px;
  }
}
@media only screen and (max-width: 600px) {
  .box-chapter {
    margin-bottom: 60px;
  }
  .box-chapter .image {
    max-height: 380px;
  }
  .box-chapter > .title {
    height: 60px;
  }
}
.button-export {
  height: 32px;
  width: 98px;
  color: #F57C63;
  display: inline-block;
  border-radius: 16px;
  border: 2px solid #F57C63;
  font-size: 0.875rem;
  text-align: center;
  line-height: 1.75rem;
  transition: all 0.1s;
}
.button-export:hover {
  border-color: #DA654C;
  background-color: #DA654C;
  color: #fff;
}
.button-export:active {
  border-color: #F57C63;
  background-color: #F57C63;
  color: #ffffff;
}
.button-filter {
  position: relative;
  color: #000;
  background-color: #F6F6F6;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid #fff;
}
.button-filter:last-child {
  border: none;
}
.button-filter.-active {
  color: #F57C63;
}
.button-filter.-active > .icon-checked {
  display: inline-block;
  color: #F57C63;
  position: absolute;
  right: 1rem;
  top: 23px;
}
.button-filter:hover {
  color: #DA654C;
}
.button-filter.-hidden {
  display: none;
}
.button-filter > .icon-filter,
.button-filter > .icon-checked {
  display: none;
}
.button-filter.-toggle {
  color: #fff;
  background-color: #F57C63;
}
.button-filter.-toggle > .icon-checked {
  display: none;
}
.button-filter.-toggle > .icon-filter {
  display: inline-block;
  margin-right: 1rem;
}
/*
.button-filter {
    
    margin-bottom: 20px;
    padding: 10px;
    width: 132px;
    height: 44px;
    border-radius: 22px;
    background-color: @brand-color;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.3);
    color: white;
    font-size: rem(16px);
    letter-spacing: rem(0.48px);
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    transition: all 0.2s;

    &:hover {
        background-color: @hover-color;
    }

    &.-active{
        background-color: @dark-hover-color;
        box-shadow: 0 6px 6px 0 rgba(0,0,0,0.3);
    }
    &.-hidden {
        display:none;
    }
}

*/
.button-teacher {
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  width: 400px;
  text-align: right;
  display: block;
  font-weight: bold;
}
.button-teacher:hover {
  color: #DA654C;
}
.button-teacher .icon {
  color: #F57C63;
  height: 26px;
  width: 26px;
  vertical-align: text-top;
}
.button-teacher.-close {
  text-align: left;
  color: #000;
}
.button-teacher.-close .icon {
  transform: rotate(-180deg);
}
.button-teacher.-close:hover {
  color: #DA654C;
}
.caption-element > p {
  color: #6a6a6a;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  font-weight: 300;
}
.carousel-element {
  position: relative;
}
.carousel-element.-lightbox {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: scroll;
  top: 0px;
  left: 0px;
  max-width: 100%;
  height: 100%;
  z-index: 20;
}
.carousel-element.-lightbox .close {
  display: block;
  position: fixed;
  height: 100%;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  z-index: 10;
  cursor: pointer;
}
.carousel-element.-lightbox .navi-left,
.carousel-element.-lightbox .navi-right {
  width: 48px;
  height: 48px;
}
.carousel-element.-lightbox .navi-right {
  right: 0px;
  transform: rotate(180deg) translateY(0%);
}
.carousel-element.-lightbox .navi-left {
  left: 0px;
  transform: translateY(0%);
}
.carousel-element.-lightbox .outer-stage {
  height: 100%;
  overflow-y: auto;
}
.carousel-element.-lightbox .outer-stage .inner-stage {
  height: 100%;
}
.carousel-element.-lightbox .outer-stage .inner-stage .lightbox {
  min-width: 100%;
  width: 100%;
  text-align: center;
}
.carousel-element.-lightbox .outer-stage .inner-stage .lightbox .container {
  width: 80%;
  margin: 0 auto;
  background-color: transparent;
}
.carousel-element.-lightbox .outer-stage .inner-stage .lightbox .container .inner .image-container {
  position: relative;
  height: 100%;
  max-height: 100vh;
  margin-top: 100px;
  display: inline-block;
}
.carousel-element.-lightbox .outer-stage .inner-stage .lightbox .container .inner .image {
  margin: 0 auto;
  display: block;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.carousel-element.-lightbox .outer-stage .inner-stage .lightbox .open {
  display: none;
}
.carousel-element.-lightbox .outer-stage .inner-stage .lightbox .caption-element {
  display: none;
}
.carousel-element .close {
  display: none;
}
.carousel-element .navi-left,
.carousel-element .navi-right {
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 5;
  background-color: #F57C63;
  width: 44px;
  height: 44px;
}
.carousel-element .navi-left svg,
.carousel-element .navi-right svg {
  width: 44px;
  height: 44px;
}
.carousel-element .navi-left {
  left: 0px;
  transform: translateY(-50%);
}
.carousel-element .navi-right {
  right: 0px;
  transform: rotate(180deg) translateY(50%);
}
.carousel-element .outer-stage {
  overflow: hidden;
}
.carousel-element .outer-stage .inner-stage {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: left 0.4s ease-out;
  left: 0px;
  position: relative;
}
.carousel-element .outer-stage .inner-stage .lightbox {
  height: 485px;
}
.carousel-element .outer-stage .inner-stage .lightbox .container {
  background-color: #6A6A6A;
  height: 450px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-element .outer-stage .inner-stage .lightbox .container .image {
  width: auto;
  max-height: 450px;
  margin: 0 auto;
  display: block;
}
.carousel-element .outer-stage .inner-stage .lightbox .caption-element {
  display: block;
  width: 100%;
  margin-top: 16px;
}
.chapter-cover {
  height: 450px;
  margin-bottom: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chapter-cover > .title {
  text-transform: uppercase;
  width: 940px;
  color: #ffffff;
  font-size: 4rem;
  font-weight: demi;
  line-height: 4.625rem;
  text-align: center;
  letter-spacing: 0.11875rem;
  text-shadow: 0.1px 0.1px 16px #6a6a6a73;
}
@media only screen and (max-width: 600px) {
  .chapter-cover {
    margin-bottom: 3.0625rem;
    height: 340px;
  }
  .chapter-cover > .title {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
}
.common-page {
  margin-top: 5.3125rem;
}
.common-page h1,
.common-page h2,
.common-page h3 {
  font-weight: bold;
  color: black;
  font-size: 3rem;
  line-height: 3.625rem;
  text-align: center;
  margin-bottom: 3.75rem;
}
.common-page > .paragraph-element {
  margin-bottom: 1.5625rem;
}
.common-page > .image-element {
  margin-bottom: 1.5625rem;
}
.common-page > .delimiter-line {
  margin: 3.125rem 0;
}
@media only screen and (max-width: 600px) {
  .common-page {
    margin-top: 0rem;
  }
  .common-page.-nocover {
    margin-top: 11.25rem;
  }
  .common-page h1,
  .common-page h2,
  .common-page h3 {
    font-size: 1.625rem;
    line-height: 2.125rem;
  }
  .common-page > .title-element {
    margin-bottom: 2.5rem;
  }
  .common-page > .paragraph-element {
    margin-bottom: 0.9375rem;
  }
  .common-page > .image-element {
    margin-bottom: 0.9375rem;
  }
  .common-page > .delimiter-line {
    margin: 1.875rem 0;
  }
}
.container-questions {
  margin-bottom: 5.625rem;
}
.container-questions .main-title {
  margin-bottom: 3.75rem;
  text-align: center;
  font-weight: bold;
  color: black;
}
.container-questions .number {
  margin-bottom: 2.875rem;
  color: #F57C63;
  width: 100%;
  text-align: center;
  border-bottom: 6px solid #F57C63;
  line-height: 0.05em;
  font-size: 3rem;
}
.container-questions .number span {
  padding: 0 20px;
  background-color: #fff;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .container-questions {
    margin-bottom: 2rem;
  }
  .container-questions > .number {
    margin-bottom: 1.25rem;
    font-size: 1.625rem;
  }
}
.container-teacher {
  border-bottom: 2px solid #F57C63;
}
.container-teacher.sidebar-login {
  border-bottom: 0px;
}
.container-teacher:last-child {
  border-bottom: none;
}
.container-teacher .module-title {
  padding-top: 20px;
  margin-bottom: 30px;
}
.container-teacher .title.title-download {
  border-bottom: 2px solid #F57C63;
  cursor: auto;
  margin: 0px;
  padding: 10px 0px;
}
.container-teacher .title {
  margin: 0px -18px 0px -27px;
  padding: 10px 0px 10px 27px;
  position: relative;
  cursor: pointer;
}
.container-teacher .title.-chapter.-current {
  border-left: 3px solid #F57C63;
  background-color: #E9E9E9;
}
.container-teacher .title.-chapter:hover {
  background-color: #E9E9E9;
}
.container-teacher .title.-background:hover {
  background-color: #E9E9E9;
}
.container-teacher .title .title-content {
  display: inline-block;
  margin-right: 34px;
}
.container-teacher .title .icon {
  color: #F57C63;
  position: absolute;
  top: 14px;
  right: 17px;
}
.container-teacher .content {
  padding-bottom: 30px;
}
.container-teacher .content .content-section {
  margin-bottom: 20px;
}
.container-teacher .content .content-section.-inline .section-title {
  display: inline;
  margin-right: 5px;
}
.container-teacher .content .content-section .section-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 5px;
}
.container-teacher .content .content-section p {
  margin-bottom: 10px;
}
.container-teacher .content .content-section ul,
.container-teacher .content .content-section ol {
  margin-left: 30px;
}
.container-teacher .content .content-section ul li,
.container-teacher .content .content-section ol li {
  margin-bottom: 10px;
}
.container-teacher .content.-close {
  display: none;
}
.container-teacher .export {
  margin-top: 32px;
}
.container-teacher .export > .button-export {
  margin-right: 29px;
}
.container-teacher .export .download {
  display: block;
  color: #000;
  margin: 11px 0px;
}
.container-teacher .export .download:hover {
  color: #F57C63;
  cursor: pointer;
}
.container-teacher .export p {
  font-size: 18px;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 35px;
}
@media only screen and (max-width: 600px) {
  .container-teacher .module-title {
    margin-bottom: 20px;
  }
  .container-teacher .export {
    margin-top: 20px;
  }
  .container-teacher .export p {
    margin-bottom: 20px;
  }
}
.delimiter-line {
  width: 100%;
  border-bottom: 6px solid #F57C63;
}
@media only screen and (max-width: 600px) {
  .delimiter-line {
    border-bottom: 4px solid #F57C63;
  }
}
.feedback-container {
  text-align: right;
  margin-bottom: 20px;
}
.feedback-container .button {
  padding: 0;
  cursor: pointer;
}
.feedback-container .button:focus {
  outline: none;
}
.feedback-panel {
  padding: .75rem 1.25rem;
  background-color: #b4b4b4;
  border-radius: 10px;
  border: 1px solid transparent;
  margin-bottom: 1.875rem;
}
.feedback-panel.-correct {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.feedback-panel.-wrong {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.filter-container {
  position: fixed;
  right: 40px;
  bottom: 0px;
  z-index: 10;
  box-shadow: 0.1px 0.1px 16px #6a6a6a54;
}
@media only screen and (max-width: 600px) {
  .filter-container {
    right: 16px;
  }
}
[class^="icon-"],
[class*=" icon-"] {
  height: 32px;
  width: 32px;
  display: inline-block;
  fill: currentColor;
  transition: transform 0.5s;
}
[class^="icon-"].-small,
[class*=" icon-"].-small {
  height: 20px;
  width: 20px;
}
[class^="icon-"].-sub,
[class*=" icon-"].-sub {
  vertical-align: sub;
}
[class^="icon-"].-inverted,
[class*=" icon-"].-inverted {
  transform: rotate(-180deg);
}
[class^="icon-"].-brand,
[class*=" icon-"].-brand {
  color: #F57C63;
}
[class^="icon-"].-rspacer,
[class*=" icon-"].-rspacer {
  margin-right: 10px;
}
.iconcontainer.-inverted > [class^="icon-"],
.iconcontainer.-inverted > [class*=" icon-"] {
  transform: rotate(-180deg);
}
.image-element.-small {
  max-width: 50%;
}
@media only screen and (max-width: 600px) {
  .image-element.-small {
    max-width: 100%;
  }
}
.lightbox {
  min-width: 100%;
}
.lightbox .container {
  position: relative;
  display: inline-block;
}
.lightbox .container .open {
  position: absolute;
  bottom: 8px;
  right: 0px;
  width: 32px;
  height: 32px;
  transform: translate(-50%, -50%);
}
.lightbox .open,
.lightbox .close {
  cursor: pointer;
}
.lightbox .open {
  display: block;
}
.lightbox .close {
  display: none;
}
.lightbox.-open {
  position: fixed;
  height: 100%;
  z-index: 100;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
}
.lightbox.-open .caption-element {
  display: none;
}
.lightbox.-open .container {
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 100%;
}
.lightbox.-open .container .inner {
  display: inline-block;
  position: relative;
  margin: 20px 0px;
}
.lightbox.-open .close {
  display: block;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;
}
.lightbox.-open .open {
  display: none;
}
.lightbox.-open .caption {
  text-align: center;
  margin-bottom: 16px;
}
.main-navigation {
  position: absolute;
  top: 0;
  z-index: 100;
  color: #F57C63;
}
.main-navigation .hamburger:hover {
  cursor: pointer;
}
.main-navigation ul {
  background-color: #fff;
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: #000;
}
.main-navigation li {
  list-style-type: none;
  line-height: 1.875rem;
  cursor: pointer;
  display: block;
  padding: 8px 0 8px 20px;
}
.main-navigation li a {
  color: #000;
}
.main-navigation .level1 {
  width: 23.75rem;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.25);
}
.main-navigation .level1:hover {
  color: #DA654C;
}
.main-navigation .level1 .book,
.main-navigation .level1 .impressum {
  padding-left: 20px;
}
.main-navigation .level1 .impressum:hover {
  color: #DA654C;
}
.main-navigation .level1 .icon {
  width: 36px;
  height: 18px;
  color: #F57C63;
  position: absolute;
  right: 20px;
}
.main-navigation .level1.-active .book:first-child {
  margin-left: -20px;
  padding: 6px 0 6px 37px;
  border-left: 3px solid #F57C63;
  color: #F57C63;
}
.main-navigation .level1.-active .icon {
  transform: rotate(180deg);
}
.main-navigation .level1.-active .level2 {
  background-color: #f6f6f6;
  padding-left: 27px;
  margin-left: -20px;
}
.main-navigation .level1.-active .level2 li a {
  display: block;
}
.main-navigation .level1.-active .level2 li:hover a {
  color: #DA654C;
}
.main-navigation .level1:first-child:hover {
  color: #DA654C;
}
.main-navigation .-close {
  display: none;
}
@media only screen and (max-width: 600px) {
  .main-navigation .level1 {
    width: 21.25rem;
  }
}
.module-label {
  color: #6a6a6a;
  margin-bottom: 10px;
  font-size: 16px;
}
.module-label.-half {
  width: 50%;
  display: inline-block;
}
.module-label .icon {
  color: #F57C63;
  margin-right: 9px;
}
.page-fluid {
  padding: 0 1rem;
  max-width: 40.125rem;
}
@media only screen and (max-width: 600px) {
  .content  .page-fluid {
    padding: 0 0;
  }
}
.page-footer {
  width: 100%;
  background-color: #F57C63;
  margin-top: 200px;
  height: 80px;
  display: flex;
  align-items: center;
}
.page-footer .text {
  color: #fff;
  margin-left: 32px;
}
.page-footer .text .impressum {
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .page-footer {
    margin-top: 160px;
  }
}
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
}
.page-header .border-bottom {
  padding-bottom: 11px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-left: 16px;
  margin-right: 16px;
}
.page-header .logo-section {
  width: 100%;
  height: 80px;
  background-color: #F57C63;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  line-height: 0px;
}
.page-header .logo-section .title {
  font-size: 2.0625rem;
  line-height: 3.125rem;
  letter-spacing: 0.053125rem;
  font-weight: bold;
  color: #fff;
}
.page-header .logo-section .logo {
  width: 96px;
  height: 25px;
  margin-left: 11px;
  margin-right: 50px;
  margin-top: 24px;
  margin-bottom: 24px;
  visibility: visible;
  opacity: 1;
}
.page-header .main-navigation {
  display: inline-block;
}
.page-header .button-login {
  display: inline-block;
  margin-left: 52px;
  vertical-align: text-top;
}
.page-header .button-login a {
  font-size: 1.125rem;
  line-height: 1.625rem;
  color: #000000;
  text-transform: uppercase;
  font-weight: bold;
}
.page-header .button-login a .navi-text {
  vertical-align: text-bottom;
}
.page-header .button-login a svg {
  vertical-align: text-top;
  color: #F57C63;
}
.page-header .button-login:hover a {
  color: #DA654C;
}
.page-header .button-login:active a {
  color: #DA654C;
}
.page-header .sidebar-teacher {
  right: 0;
}
.page-header .site-title {
  display: none;
}
.page-header .home-button {
  display: none;
}
.page-header.-collabse {
  padding-top: 0px;
}
.page-header.-collabse .logo-section {
  visibility: hidden;
  height: 0px;
  transition: height 0.5s;
}
.page-header.-collabse .title {
  display: none;
}
.page-header.-collabse .site-title {
  position: relative;
  left: 75px;
  width: 100%;
  white-space: nowrap;
  display: table-cell;
  font-size: 18px;
  vertical-align: middle;
}
.page-header.-collabse .home-button {
  display: table-cell;
  padding-left: 50px;
}
.page-header.-collabse .home-button .icon {
  display: inline-block;
  height: 26px;
  width: 26px;
  vertical-align: top;
}
@media only screen and (max-width: 1024px) {
  .page-header.-collabse .site-title {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .page-header.-collabse .site-title {
    display: none;
  }
  .page-header .logo-section {
    display: block;
  }
  .page-header .logo-section .logo {
    margin-top: 14px;
    margin-bottom: 9px;
    margin-left: 8px;
  }
  .page-header .logo-section .title {
    font-size: 18px;
    line-height: 1.8125rem;
    font-weight: bold;
    margin-left: 16px;
  }
  .page-header .button-login .navi-text {
    display: none;
  }
  .page-header .button-teacher {
    width: 30px;
  }
  .page-header .button-teacher .navi-text {
    display: none;
  }
}
.page-layout {
  max-width: 100%;
  margin: auto;
}
.page-layout > .content {
  padding-top: 162px;
}
.page-layout > .content.-sidebar {
  padding-right: 400px;
}
@media only screen and (max-width: 600px) {
  .page-layout > .content {
    padding-top: 0;
    margin-top: 152px;
  }
  .page-layout > .content.-sidebar {
    padding-right: 0px;
    padding-top: 0px;
  }
}
.page-overview {
  max-width: 58.75rem;
}
.page-overview .introduction {
  margin-bottom: 5rem;
  color: #000000;
  font-size: 2.375rem;
  font-weight: 500;
  line-height: 50px;
}
@media only screen and (max-width: 600px) {
  .page-overview > .introduction {
    font-size: 1.625rem;
    line-height: 2.125rem;
  }
}
.paragraph-element {
  color: #000000;
}
.paragraph-element.-introduction {
  font-size: 1.625rem;
  line-height: 2.375rem;
  font-weight: 500;
}
.paragraph-element.-default {
  font-size: 1.375rem;
  line-height: 2.1875rem;
  font-weight: 300;
}
.paragraph-element strong,
.paragraph-element b {
  font-weight: bold;
}
.paragraph-element ul,
.paragraph-element ol {
  margin-left: 60px;
  margin-bottom: 30px;
}
.paragraph-element ul li {
  margin-bottom: 10px;
}
.paragraph-element .abbreviation {
  position: relative;
  text-decoration: none;
  border-bottom: 3px dotted black;
  line-height: 20px;
  cursor: help;
}
.paragraph-element .abbreviation .tooltip {
  display: none;
}
.paragraph-element .abbreviation.-right .tooltip {
  left: 0px;
}
.paragraph-element .abbreviation.-left .tooltip {
  right: 0px;
}
.paragraph-element .abbreviation.-top .tooltip {
  bottom: 30px;
}
.paragraph-element .abbreviation.-bottom .tooltip {
  top: 30px;
}
.paragraph-element .abbreviation.-big.-open .tooltip,
.paragraph-element .abbreviation.-big:hover .tooltip {
  width: auto;
  width: 50vw;
  white-space: normal;
}
.paragraph-element .abbreviation.-sidebar.-open .tooltip,
.paragraph-element .abbreviation.-sidebar:hover .tooltip {
  width: auto;
  width: 200px;
  white-space: normal;
}
.paragraph-element .abbreviation.-open .tooltip,
.paragraph-element .abbreviation:hover .tooltip {
  display: block;
  position: absolute;
  z-index: 10;
  white-space: nowrap;
  background-color: #1e1e1e;
  color: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
  font-size: 14px;
  padding: 3px 5px;
}
@media only screen and (max-width: 600px) {
  .paragraph-element.-introduction {
    font-size: 1.375rem;
    line-height: 2rem;
  }
  .paragraph-element.-default {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .paragraph-element ul,
  .paragraph-element ol {
    margin-bottom: 15px;
  }
}
.question-item {
  margin-bottom: 3.75rem;
}
.question-item > .icon {
  display: inline-block;
  color: #F57C63;
  height: 30px;
  width: 30px;
  vertical-align: text-bottom;
  margin-right: 5px;
}
.question-item .title {
  color: #F57C63;
  text-transform: uppercase;
  margin-bottom: 0.9375rem;
  font-size: 1.625rem;
  font-weight: bold;
  display: inline-block;
}
.question-item .paragraph-element,
.question-item .image-element,
.question-item ul,
.question-item ol,
.question-item .iframe-element,
.question-item .video-element,
.question-item .audio-element,
.question-item .carousel-element {
  margin-bottom: 30px;
}
.question-item .paragraph-element.-nomargin,
.question-item .image-element.-nomargin,
.question-item ul.-nomargin,
.question-item ol.-nomargin,
.question-item .iframe-element.-nomargin,
.question-item .video-element.-nomargin,
.question-item .audio-element.-nomargin,
.question-item .carousel-element.-nomargin {
  margin-bottom: 0;
}
.question-item ul,
.question-item ol {
  margin-left: 60px;
}
.question-item ul li,
.question-item ol li {
  margin-bottom: 10px;
}
@media only screen and (max-width: 600px) {
  .question-item .title {
    font-size: 1.375rem;
    line-height: 2rem;
    font-weight: demi;
  }
  .question-item .paragraph-element,
  .question-item .image-element,
  .question-item ul,
  .question-item ol,
  .question-item .iframe-element,
  .question-item .video-element,
  .question-item .audio-element,
  .question-item .carousel-element {
    margin-bottom: 15px;
  }
}
.question-text > .dropdown.-wrong {
  background-color: #f8d7da;
}
.question-text > .dropdown.-correct {
  background-color: #d4edda;
}
.sidebar-login {
  background-color: #F57C63;
  margin-left: -27px;
  margin-right: -18px;
  padding-left: 27px;
  padding-right: 18px;
}
.sidebar-login a {
  color: #fff;
  padding: 10px 0;
  display: inline-block;
}
.sidebar-login .icon {
  width: 22px;
  height: 22px;
  margin-right: 16px;
  vertical-align: sub;
}
.sidebar-login .icon.icon-arrow-down {
  color: inherit;
  transform: rotate(-90deg);
  position: relative;
  top: 0;
  right: -40px;
}
.sidebar-login .title {
  display: inline-block;
}
.sidebar-login:hover {
  background-color: #DA654C;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .sidebar-login .icon.icon-arrow-down {
    right: -20px;
  }
}
.sidebar-teacher {
  font-size: 18px;
  background-color: #F6F6F6;
  box-shadow: -1px 1px 0 0 rgba(0, 0, 0, 0.25);
  padding-left: 27px;
  padding-right: 34px;
  width: 416px;
  position: fixed;
  right: 0px;
  top: 133px;
  bottom: 0px;
  display: block;
  overflow-y: auto;
  z-index: 10;
}
.sidebar-teacher.-teacher .container-teacher.sort-0 .title {
  border-top: 5px solid #F57C63;
}
.sidebar-teacher .label {
  font-size: 14px;
  display: inline-block;
}
.sidebar-teacher .meta-space {
  margin-bottom: 64px;
}
.sidebar-teacher.-close {
  display: none;
}
.sidebar-teacher.-collabse {
  top: 55px;
  transition-timing-function: linear;
  transition: top 0.5s;
}
@media only screen and (max-width: 600px) {
  .sidebar-teacher {
    top: 133px;
    padding-right: 20px;
    width: 343px;
  }
}
h1 {
  font-size: 4rem;
  letter-spacing: 0.11875rem;
  line-height: 4.375rem;
  font-weight: normal;
}
h2 {
  font-size: 3rem;
  line-height: 3.625rem;
  font-weight: normal;
}
h3 {
  line-height: 1.6875rem;
  font-size: 1.25rem;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 1.625rem;
    line-height: 2.125rem;
  }
  h3 {
    line-height: 1.5625rem;
    font-size: 1.125rem;
  }
}
